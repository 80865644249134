import NavItem from "../NavItem/NavItem";
import "./navmobile.css";

function NavMobile({ setOpen, setEnquire }) {
  return (
    <div className="navmobile">
      <NavItem setOpen={setOpen} setEnquire={setEnquire} />
    </div>
  );
}

export default NavMobile;

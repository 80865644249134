import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Homepage from "./pages/homepage/Homepage";
import YachtPage from "./pages/yachtpage/YachtPage";
import FacilitiesPage from "./pages/facilitiespage/FacilitiesPage";
import VoyagePage from "./pages/voyagepage/VoyagePage";
import GalleryPage from "./pages/gallerypage/GalleryPage";
import ExclusivePage from "./pages/exclusivepage/ExclusivePage";
/* React Toastify */
import "react-toastify/dist/ReactToastify.css";
import DetailVoyagePage from "./pages/detailvoyagepage/DetailVoyagePage";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="yacht" element={<YachtPage />} />
        <Route path="facilities" element={<FacilitiesPage />} />
        <Route path="voyage" element={<VoyagePage />} />
        <Route path="voyage/:slug" element={<DetailVoyagePage />} />
        <Route path="gallery" element={<GalleryPage />} />
        <Route path="exclusive-offer" element={<ExclusivePage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import "./yachtpage.css";
import GoToTop from "../../helpers/goToTop";
import { useWindow } from "../../helpers/useWindow";

function YachtPage() {
  // SET MOBILE BACKGROUND
  let layer = useWindow();

  let imageHeader;

  if (layer >= 768) {
    imageHeader = "/images/yacht-page.jpg";
  } else {
    imageHeader = "/images/BANNER MOBILE/header-the yacht-mobile.jpg";
  }

  return (
    <div className="yacht-page">
      <GoToTop />
      <img src={imageHeader} alt="Yacht" />
      <div className="contents-container">
        <div className="contents-title">
          <h3>The Trans Luxury Yacht </h3>
          <p>
            Rebuilt from the M.V Salila, this luxury yacht is a truly
            magnificent charter vessel with distinctive interior design and
            alluring features such as 2 suites and 8 spacious cabins comfortably
            accommodating 20 guests over 5 tiers of dining, entertainment,
            business, and relaxation options
          </p>
        </div>
        <div className="grid-images">
          <div className="item1">
            <img src="/images/kapal-12.jpg" alt="" />
          </div>
          <div className="item2">
            <img src="/images/kapal-2.jpg" alt="" />
          </div>
          <div className="item3">
            <img src="/images/kapal-3.jpg" alt="" />
          </div>
        </div>
        <div className="contents-title">
          <h3>Yacht Specification</h3>
        </div>
        <div className="container-img-spec">
          <img src="/images/spec.png" alt="" />
        </div>
        <div className="wrapper-specification">
          <div className="total-cabin">
            <div className="title-total-cabin">Total Cabin</div>
            <div className="desc-total-cabin">
              <span>2 Suites</span>
              <span>4 Cabins Double Bed</span>
              <span>4 Cabins Twin Bed</span>
            </div>
          </div>
          <div className="level">
            <div className="title-level">Levels</div>
            <div className="desc-level">
              <span>Lower deck (cabins)</span>
              <span>Upper deck (suites &amp; spa room)</span>
              <span>
                Main saloon deck (Living Room, Bar Area, In-door Dining Room)
              </span>
              <span>
                Navigation deck (Meeting Area, Outdoor Dining Area, Navigation
                Room
              </span>
              <span>Sundeck (Bar Area, Jacuzzi, Outdoor Lounge)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YachtPage;

import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./preview.css";

function Preview({ setIsOpen }) {
  const previewRef = useRef();
  const navigate = useNavigate();

  const handleClick = () => {
    previewRef.current.style.animation = "fadeOut 2s";

    setTimeout(() => {
      setIsOpen(true);
    }, 2000);
  };

  let timer;

  const handleClickOffer = () => {
    previewRef.current.style.animation = "fadeOut 2s";
    navigate("/exclusive-offer");

    timer = setTimeout(() => {
      setIsOpen(true);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return (
    <div className="preview" ref={previewRef}>
      <video autoPlay loop muted className="video-preview">
        <source src="/images/yacht3.mp4" type="video/mp4" />
        Browser tidak support
      </video>
      <div className="contents">
        <div className="logo-preview">
          <img src="/images/logo.svg" alt="logo" />
        </div>
        <div className="explore" onClick={handleClick}>
          Explore
        </div>
        <div className="explore" onClick={handleClickOffer}>
          Exlusive Offer
        </div>
      </div>
    </div>
  );
}

export default Preview;

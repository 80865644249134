export const dataRoom = {
  title: "Room",
  type: [
    {
      id: 1,
      name: "Suites",
      featuredImg: "/images/room1.png",
      gallery: [
        { title: "1", url: "/images/preseden/preseden1.jpg" },
        { title: "2", url: "/images/preseden/preseden2.jpg" },
        { title: "3", url: "/images/preseden/preseden3.jpg" },
      ],
    },
    {
      id: 2,
      name: "Cabin Twin Bed",
      featuredImg: "/images/room2.png",
      gallery: [
        { title: "1", url: "/images/twinbed/twin1.jpg" },
        { title: "2", url: "/images/twinbed/twin2.jpg" },
        { title: "3", url: "/images/twinbed/twin3.jpg" },
      ],
    },
    {
      id: 3,
      name: "Cabin Double Bed",
      featuredImg: "/images/room3.png",
      gallery: [
        { title: "1", url: "/images/suites/suite1.jpg" },
        { title: "2", url: "/images/suites/suite2.jpg" },
        { title: "3", url: "/images/suites/suite3.jpg" },
      ],
    },
  ],
};

import { useNavigate } from "react-router-dom";
import { voyages } from "../../data/voyages";
import "./voyage.css";

function Voyage() {
  const navigate = useNavigate();

  return (
    <div className="voyage" id="voyage">
      <div className="voyage-title">
        <h3>Voyage</h3>
        <p>The best Yacht experience in Indonesia you will get</p>
      </div>
      <div className="voyage-container">
        {voyages &&
          voyages.map((item) => (
            <div
              className="card-voyage"
              key={item.id}
              onClick={() => navigate(`/voyage/${item.slug}`)}
            >
              <div className="img-voyage">
                <img src={item.image} alt="" />
              </div>
              <div className="img-title">{item.title}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Voyage;

export const dataMeeting = {
  title: "Meeting Room",
  type: [
    {
      id: 1,
      name: "",
      featuredImg: "/images/room9.png",
      gallery: [
        // { title: "1", url: "/images/preseden/preseden1.jpg" },
        // { title: "2", url: "/images/preseden/preseden2.jpg" },
        // { title: "3", url: "/images/preseden/preseden3.jpg" },
      ],
    },
    {
      id: 2,
      name: "",
      featuredImg: "/images/room7.png",
      gallery: [
        // { title: "1", url: "/images/twinbed/twin1.jpg" },
        // { title: "2", url: "/images/twinbed/twin2.jpg" },
        // { title: "3", url: "/images/twinbed/twin3.jpg" },
      ],
    },
    {
      id: 3,
      name: "",
      featuredImg: "/images/room8.png",
      gallery: [
        // { title: "1", url: "/images/suites/suite1.jpg" },
        // { title: "2", url: "/images/suites/suite2.jpg" },
        // { title: "3", url: "/images/suites/suite3.jpg" },
      ],
    },
  ],
};

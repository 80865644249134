import { useParams } from "react-router-dom";
import GoToTop from "../../helpers/goToTop";
import "./detailvoyage.css";
import { useWindow } from "../../helpers/useWindow";
import { voyages } from "../../data/voyages";

function DetailVoyagePage() {
  const { slug } = useParams();
  // console.log("slug", slug);

  // SET MOBILE BACKGROUND
  let layer = useWindow();
  let imageHeader;

  if (layer >= 768) {
    imageHeader = "/images/header-voyage.jpg";
  } else {
    imageHeader = "/images/BANNER MOBILE/header-voyage-mobile.jpg";
  }

  // Fetch Data
  const data = voyages.find((item) => item.slug === slug);
  // console.log("data :>> ", data);

  return (
    <div className="detail__voyagepage">
      <GoToTop />
      {/* <img src="/images/yacht-page.jpg" alt="Yacht" /> */}
      <img src={imageHeader} alt="Yacht" />
      <div className="contents-container">
        <div className="voyage-title">
          <h3>{data.title}</h3>
          <p>{data.subTitle}</p>
        </div>

        <div className="table__wrapper">
          <div dangerouslySetInnerHTML={{ __html: data.table }}></div>
        </div>
        <div className="additional__wrapper">
          <div dangerouslySetInnerHTML={{ __html: data.addtionalInfo }}></div>
        </div>
      </div>
    </div>
  );
}

export default DetailVoyagePage;

import "./intro.css";

function Intro() {
  return (
    <div className="intro">
      <div className="tag">
        <p>a journey</p>
        <p>beyond the sea</p>
      </div>
    </div>
  );
}

export default Intro;

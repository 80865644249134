import React from "react";
import PopUpGallery from "../popUpGallery/PopUpGallery";

function DetailGallery({ open, setOpen, detailGallery }) {
  // console.log("open :>> ", open);

  return (
    <div className="room-wrapper">
      <h4>{detailGallery.title}</h4>
      <div className="card-images-room">
        {detailGallery.type?.map((item) => (
          <div
            className="img-container"
            onClick={() => setOpen(item.gallery)}
            key={item.id}
          >
            <img src={item.featuredImg} alt="" />
            <div className="title-img-room">{item.name}</div>
          </div>
        ))}
        {/* <div className="img-container" onClick={() => setOpen2(!open2)}>
          <img src="/images/room2.png" alt="" />
          <div className="title-img-room">Cabin Twin Bed</div>
        </div>
        <div className="img-container" onClick={() => setOpen3(!open3)}>
          <img src="/images/room3.png" alt="" />
          <div className="title-img-room">Cabin Double Bed</div>
        </div> */}
      </div>
      {open.length > 0 && (
        <PopUpGallery setOpen={setOpen} open={open} dataGallery={open} />
      )}

      {/* {open && (
        <PopUpGallery
          setOpen={setOpen2}
          open={open2}
          dataGallery={popUpGallery2}
        />
      )}
      {open && (
        <PopUpGallery
          setOpen={setOpen3}
          open={open3}
          dataGallery={popUpGallery3}
        />
      )} */}
    </div>
  );
}

export default DetailGallery;

import React from "react";
import { NavLink } from "react-router-dom";
import "./navitem.css";

function NavItem({ setEnquire, setOpen }) {
  return (
    <ul>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/yacht">
          <span>The Yacht</span>
        </NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/facilities">
          <span>Facilities</span>{" "}
        </NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/voyage">
          <span>Voyage</span>{" "}
        </NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/">
          <img src="/images/logo2.png" alt="" className="logo" />{" "}
        </NavLink>
      </li>

      <li onClick={() => setOpen(false)}>
        <NavLink to="/gallery">
          <span>Gallery</span>{" "}
        </NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/exclusive-offer">
          <span> Exclusive Offer</span>{" "}
        </NavLink>
      </li>
      <li onClick={() => setEnquire(true)}>
        <span className="enquire-btn">Enquire</span>
      </li>
    </ul>
  );
}

export default NavItem;

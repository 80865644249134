import { useRef } from "react";
import "./packages.css";

function Packages({ setPackages, packages }) {
  console.log("packages :>> ", packages);
  const refPackages = useRef();
  const handleClolse = () => {
    refPackages.current.style.animation = "hiddenPackages 0.5s";
    setTimeout(() => {
      setPackages(null);
    }, 500);
  };
  return (
    <div className="wrapper-packages" onClick={handleClolse}>
      <div className="container-packages" ref={refPackages}>
        <div className="text-box">
          <p>
            ITINERARY {packages.from} - {packages.to}
          </p>
          <p>
            {packages.hari} Days {packages.malam} Nights
          </p>
          {/* <p>TOTAL DISTANCE / TIME:</p>
          <p>370Nm / 37H</p> */}
        </div>
        <div className="text-outerbox">
          <h5>EMBARKING/ DISEMBARKING: {packages.embarking}</h5>
          {packages.details &&
            packages.details.map((detail, idx) => (
              <div className="days" key={idx}>
                <div className="days-box">DAY {detail.day}</div>
                <div className="days-box-desc">
                  {/* <p>BALI - GILI ISLANDS 154 Nm, 15.4 hours</p>
              <p>Bali-Gili 55 Nm, Gili-Teluk Saleh overnight 99 Nm</p> */}
                  <p>{detail.destinasi}</p>
                </div>
              </div>
            ))}
          {/* <div className="days">
            <div className="days-box">DAY 2</div>
            <div className="days-box-desc">
              <p> TELUK SALEH - MOYO – SATONDA 120 Nm, 12 hours</p>
              <p>Teluk Saleh-Moyo 20 Nm, Moyo-Santonda 22 Nm,</p>
              <p> Satonda-Sangeang Overnight 78 Nm</p>
            </div>
          </div>
          <div className="days">
            <div className="days-box">DAY 3</div>
            <div className="days-box-desc">
              <p>SANGEANG - GILI BANTA 36 Nm, 3.6 hours</p>
              <p>
                {" "}
                Sangeang-Gili Banta 16 Nm, Gili Banta-Karang Makassar 20 Nm
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Packages;

import { useRef, useState } from "react";
import { gallerys } from "../../data/gallery";
import "./gallery.css";

function Gallery() {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef();

  const handleClose = () => {
    wrapperRef.current.style.animation = "hidden 1s";

    setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };
  return (
    <div className="gallery">
      <div className="gallery-title">
        <h3>Gallery</h3>
        <p>The best Yacht experience in Indonesia you will get</p>
      </div>
      <div className="gallery-image">
        {isOpen && (
          <div className="wrapper-video" onClick={handleClose} ref={wrapperRef}>
            {/* <div className="close-btn" onClick={() => setIsOpen(false)}>
              x
            </div> */}
            <video muted controls>
              {/* <source src="/images/gallery.mp4" type="video/mp4" /> */}
              <source
                src="https://bucket.tms.id/media/tly/VideoGalleryBaru.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        <img
          src="/images/thumbnail.png"
          alt="gallery"
          onClick={() => setIsOpen(true)}
        />
      </div>
      <div className="gallery-container">
        {gallerys &&
          gallerys.map((item) => (
            <div className="img-wrapper" key={item.id}>
              <img src={item.image} alt="" />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Gallery;

import React, { useState } from "react";
import Gallery from "../../components/gallery/Gallery";
import Hero from "../../components/hero/Hero";
import Intro from "../../components/intro/Intro";
import Preview from "../../components/preview/Preview";
import Tagline from "../../components/tagline/Tagline";
import Voyage from "../../components/voyage/Voyage";
import GoToTop from "../../helpers/goToTop";

function Homepage() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <GoToTop />
      {!isOpen && <Preview setIsOpen={setIsOpen} />}
      <Intro />
      <Hero />
      <Gallery />
      <Voyage />
      <Tagline />
    </div>
  );
}

export default Homepage;

export const dataLeisure = {
  title: "Leisure Activities",
  type: [
    {
      id: 1,
      name: "Living Room",
      featuredImg: "/images/room12.png",
      gallery: [
        { title: "1", url: "/images/LIVING ROOM/821A7954.jpg" },
        { title: "2", url: "/images/LIVING ROOM/YATCH15.jpg" },
      ],
    },
    {
      id: 2,
      name: "Jacuzzi",
      featuredImg: "/images/room10.png",
      gallery: [
        { title: "1", url: "/images/JACUZZI/DSCF7852.jpg" },
        { title: "2", url: "/images/JACUZZI/DSCF7856.jpg" },
        { title: "3", url: "/images/JACUZZI/YATCH16.jpg" },
      ],
    },
    {
      id: 3,
      name: "The Spa",
      featuredImg: "/images/room11.png",
      gallery: [{ title: "1", url: "/images/THE SPA/821A8010.jpg" }],
    },
  ],
};

import "./gallerypage.css";
import GoToTop from "../../helpers/goToTop";
import { useState } from "react";
import DetailGallery from "../../components/DetailGallery/DetailGallery";
import { dataRoom } from "../../data/dataRoom";
import { dataDining } from "../../data/dataDining";
import { dataMeeting } from "../../data/dataMeeting";
import { dataLeisure } from "../../data/dataLeisure";
import { useWindow } from "../../helpers/useWindow";

function GalleryPage() {
  const [openRoom, setOpenRoom] = useState([]);
  const [openDining, setOpenDining] = useState([]);
  const [openMeeting, setOpenMeeting] = useState([]);
  const [openLeisure, setOpenLeisure] = useState([]);

  // SET MOBILE BACKGROUND
  let layer = useWindow();

  let imageHeader;

  if (layer >= 768) {
    imageHeader = "/images/header-gallery2.jpg";
  } else {
    imageHeader = "/images/BANNER MOBILE/header-gallery2-mobile.jpg";
  }

  return (
    <div className="gallery-page">
      <GoToTop />
      <img src={imageHeader} alt="Yacht" />
      <div className="contents-container">
        <div className="contents-title">
          <h3>Gallery</h3>
          <p>
            Set out aboard and sail into the deep blue waters of Indonesian
            archipelago and enjoy customized itineraries and leisure activities
            onboard
          </p>
        </div>
        {/* <div className="full-image">
          <img src="/images/gallery1.jpg" alt="" />
        </div>
        <div className="grid-images">
          <div className="item1">
            <img src="/images/gallery2.jpg" alt="" />
          </div>
          <div className="item2">
            <img src="/images/gallery3.jpg" alt="" />
          </div>
          <div className="item3">
            <img src="/images/gallery4.jpg" alt="" />
          </div>
        </div> */}
        {/* Flex */}
        {/* <div className="flex-images">
          <div className="img-item-v2">
            <img src="/images/gallery5.jpg" alt="" />
          </div>
          <div className="img-item-v2">
            <img src="/images/gallery6.jpg" alt="" />
          </div>
        </div> */}
        {/* UPDATE */}
        <DetailGallery
          open={openRoom}
          setOpen={setOpenRoom}
          detailGallery={dataRoom}
        />
        {/* <div className="room-wrapper">
          <h4>Room</h4>
          <div className="card-images-room">
            <div className="img-container" onClick={() => setOpen1(!open1)}>
              <img src="/images/room1.png" alt="" />
              <div className="title-img-room">Suites</div>
            </div>
            <div className="img-container" onClick={() => setOpen2(!open2)}>
              <img src="/images/room2.png" alt="" />
              <div className="title-img-room">Cabin Twin Bed</div>
            </div>
            <div className="img-container" onClick={() => setOpen3(!open3)}>
              <img src="/images/room3.png" alt="" />
              <div className="title-img-room">Cabin Double Bed</div>
            </div>
          </div>
          {open1 && (
            <PopUpGallery
              setOpen={setOpen1}
              open={open1}
              dataGallery={popUpGallery1}
            />
          )}

          {open2 && (
            <PopUpGallery
              setOpen={setOpen2}
              open={open2}
              dataGallery={popUpGallery2}
            />
          )}
          {open3 && (
            <PopUpGallery
              setOpen={setOpen3}
              open={open3}
              dataGallery={popUpGallery3}
            />
          )}
        </div> */}
        {/* Dining Room */}
        <DetailGallery
          open={openDining}
          setOpen={setOpenDining}
          detailGallery={dataDining}
        />
        {/* <div className="room-wrapper">
          <h4>Dining Room</h4>
          <div className="card-images-room">
            <div className="img-container" onClick={() => setOpen1(!open1)}>
              <img src="/images/room5.png" alt="" />
              <div className="title-img-room">Indoor</div>
            </div>
            <div className="img-container" onClick={() => setOpen2(!open2)}>
              <img src="/images/room6.png" alt="" />
              <div className="title-img-room">Outdoor</div>
            </div>
            <div className="img-container" onClick={() => setOpen3(!open3)}>
              <img src="/images/room4.png" alt="" />
              <div className="title-img-room">Bar &amp; Sundeck Bar</div>
            </div>
          </div>
        </div> */}
        {/* Meeting Room */}
        <DetailGallery
          open={openMeeting}
          setOpen={setOpenMeeting}
          detailGallery={dataMeeting}
        />
        {/* <div className="room-wrapper">
          <h4>Meeting Room</h4>
          <div className="card-images-room">
            <div className="img-container" onClick={() => setOpen1(!open1)}>
              <img src="/images/room9.png" alt="" />
              <div className="title-img-room">Suites</div>
            </div>
            <div className="img-container" onClick={() => setOpen2(!open2)}>
              <img src="/images/room7.png" alt="" />
              <div className="title-img-room">Cabin Twin Bed</div>
            </div>
            <div className="img-container" onClick={() => setOpen3(!open3)}>
              <img src="/images/room8.png" alt="" />
              <div className="title-img-room">Cabin Double Bed</div>
            </div>
          </div>
        </div> */}
        {/* Leisure Activities */}
        <DetailGallery
          open={openLeisure}
          setOpen={setOpenLeisure}
          detailGallery={dataLeisure}
        />
        {/* <div className="room-wrapper">
          <h4>Leisure Activities</h4>
          <div className="card-images-room">
            <div className="img-container" onClick={() => setOpen1(!open1)}>
              <img src="/images/room12.png" alt="" />
              <div className="title-img-room">Living Room</div>
            </div>
            <div className="img-container" onClick={() => setOpen2(!open2)}>
              <img src="/images/room10.png" alt="" />
              <div className="title-img-room">Jacuzzi</div>
            </div>
            <div className="img-container" onClick={() => setOpen3(!open3)}>
              <img src="/images/room11.png" alt="" />
              <div className="title-img-room">The Spa</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default GalleryPage;

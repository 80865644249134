export let voyages = [
  {
    id: 1,
    title: "3 DAYS 2 NIGHTS",
    subTitle: "ITTENARY - 3 DAYS 2 NIGHTS FULL BOARD (EXCLUDING ALCOHOL)",
    image: "/images/voyage3.jpeg",
    slug: "3-days-2-night",
    table: `
    <table width="974">
      <thead>
        <tr>
          <td width="101">
          <p><strong>Date</strong></p>
          </td>
          <td width="104">
          <p><strong>Time</strong></p>
          </td>
          <td width="358">
          <p><strong>Activity</strong></p>
          </td>
          <td width="253">
          <p><strong>Attraction</strong></p>
          </td>
          <td width="158">
          <p><strong>Venue</strong></p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="14" width="101" class="date">
          <p><strong>DAY 1</strong></p>
          </td>
          <td width="104">
          <p>10:00 /10:30</p>
          </td>
          <td width="358">
          <p>Meet and Greet at AIRPORT</p>
          <p>Luggage handling</p>
          </td>
          <td width="253">
          <p>&nbsp;</p>
          </td>
          <td width="158">
          <p>Komodo Airport</p>
          </td>
        </tr>
      <tr>
      <td width="104">
      <p>10:30</p>
      </td>
      <td width="358">
      <p>Car transfer to Komodo Jetty</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Labuan Bajo</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>10:30 - 11:30</p>
      </td>
      <td width="358">
      <p>Transfer to Yacht by x2 boats</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Labuan Bajo</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>12:00</p>
      </td>
      <td width="358">
      <p>Arrive at Yacht - Welcoming by the</p>
      <p>Cruise Director &amp; Crews</p>
      </td>
      <td width="253">
      <p>Welcome drink served</p>
      </td>
      <td width="158">
      <p>Seated at Living</p>
      <p>Area</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>12:30 - 13:00</p>
      </td>
      <td width="358">
      <p>Safety briefing</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Living area</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>13:00 - 14:00</p>
      </td>
      <td width="358">
      <p>Escort to Cabins to freshen up</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Each Cabin</p>
      </td>
      </tr>
      <tr>
      <td rowspan="2" width="104">
      <p>&nbsp;</p>
      <p>14:00 - 17:30</p>
      </td>
      <td width="358">
      <p>Lunch</p>
      </td>
      <td width="253">
      <p>Refer to Day 1 Menu</p>
      </td>
      <td width="158">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="358">
      <p>Start cruising to Koaba Island as</p>
      <p>snacks are served</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Outdoor Dining</p>
      <p>Room</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="104">
      <p>17.30</p>
      </td>
      <td width="358">
      <p>Arrival to Koaba Island</p>
      </td>
      <td width="253">
      <p>Known as Koaba Island (the nest of</p>
      <p>ﬂying foxes)</p>
      </td>
      <td width="158">
      <p>Koaba Island</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="104">
      <p>17:30 - 18:30</p>
      </td>
      <td width="358">
      <p>Sunset</p>
      </td>
      <td width="253">
      <p>As the sky turns dark, thousands of foxes will take oﬀ and ﬂy in the direction of Flores in an endless black line stretching over the sunset sky of</p>
      <p>Komodo National Park.</p>
      </td>
      <td width="158">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>Koaba Island</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>18:30 - 19:00</p>
      </td>
      <td width="358">
      <p>Relax and freshen up for dinner</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Rooftop &amp;</p>
      <p>Cabins</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>19.00</p>
      </td>
      <td width="358">
      <p>Start cruising to Komodo Bay</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>19:00 - 21:00</p>
      </td>
      <td width="358">
      <p>Dinner</p>
      </td>
      <td width="253">
      <p>Refer to Day 1 menu</p>
      </td>
      <td width="158">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>21:00 - 22:00</p>
      </td>
      <td width="358">
      <p>After dinner &amp; relax</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Yacht</p>
      </td>
      </tr>
      
      <tr class="yellow">
      <td width="101" rowspan="12" class="date">
      <p>DAY 2</p>
      </td>
      <td width="104">
      <p>07:00</p>
      </td>
      <td width="358">
      <p>Wake up at Pink Beach</p>
      </td>
      <td width="253">
      <p>The inhabited beach with pink colored sand and an alluring view. A good spot for snorkeling and sunbathing.</p>
      <p>for snorkeling and</p>
      </td>
      <td width="158">
      <p>Pink Beach</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>07:00 - 09:00</p>
      </td>
      <td width="358">
      <p>Breakfast</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
   
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>09:00 - 11:30</p>
      </td>
      <td width="358">
      <p>Water Activities:</p>
      <p>Snorkel, Swimming / Kayaking at Pink Beach</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>&nbsp;</p>
      <p>Pink Beach</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>&nbsp;</p>
      <p>11:30 - 12:30</p>
      </td>
      <td width="358">
      <p>&nbsp;</p>
      <p>Cruise to Komodo Ranger Station</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Komodo Island</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>12:30 - 13:30</p>
      </td>
      <td width="358">
      <p>&nbsp;</p>
      <p>Lunch</p>
      </td>
      <td width="253">
      <p>Refer to Day 2 Menu</p>
      </td>
      <td width="158">
 
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>13:30 - 14:00</p>
      </td>
      <td width="358">
      <p>Speedboat to go trekking at Komodo Ranger Station</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Komodo Island</p>
      </td>
      </tr>
      
      <tr>
      <td width="104">
      <p>&nbsp;</p>
      <p>14:00 - 15:30</p>
      </td>
      <td width="358">
      <p>&nbsp;</p>
      <p>Trekking</p>
      </td>
      <td width="253">
      <p>Explore the island and
      see the Komodo dragon
      in real life.
      Recommended to wear boots/training shoes as you
      may walk through the
      savanna.
      </p>
      </td>
      <td width="158">
      <p>Komodo Ranger Station</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>15:30 - 16:00</p>
      </td>
      <td width="358">
      <p>Finish trekking</p>
      <p>Speedboat back to the Yacht</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Komodo Island</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="104">
      <p>&nbsp;</p>
      <p>16:00 - 19:30</p>
      </td>
      <td width="358">
      <p>Arrival at Yacht to relax</p>
      <p>Use Glass Bottom Boat to see the coral, swimming / snorkeling, water activities</p>
      </td>
      <td width="253">
      <p>Wonders of the ocean
      with its stunning
      Crystal Rock and the
      creatures that live in it. Some Manta Rays, sharks, or species of anthias and
      fusiliers are occasionally
      seen here.
      </p>
      </td>
      <td width="158">
      <p>&nbsp;</p>
      <p>Komodo Island</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>18:30 - 19:00</p>
      </td>
      <td width="358">
      <p>Sunset drinks/snack</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Rooftop</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>19:30 - 21:00</p>
      </td>
      <td width="358">
      <p>Dinner</p>
      </td>
      <td width="253">
      <p>Refer to Day 2 Menu</p>
      </td>
      <td width="158">
   
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>21:00</p>
      </td>
      <td width="358">
      <p>After dinner &amp; relax</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr>
      <td rowspan="7" width="101" class="date">
      <p><strong>DAY 3</strong></p>
      </td>
      <td width="104">
      <p>05:00 - 06:00</p>
      </td>
      <td width="358">
      <p>Wake and be greeted by dolphins</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Bidadari Island</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>06:00 - 08:00</p>
      </td>
      <td width="358">
      <p>Breakfast</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="104">
      <p>06:00</p>
      </td>
      <td width="358">
      <p>Arrival at Bidadari Island</p>
      </td>
      <td width="253">
      <p>Bidadari (Angel) island, is a beautiful small island in the heart of Komodo National Park. Well-known for its clear water and a long stretch of white sandy beach.</p>
      </td>
      <td width="158">
      <p>Bidadari Island</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>08:00 - 10:00</p>
      </td>
      <td width="358">
      <p>Water activities: kayaking, snorkeling, etc</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>10:00 - 11:00</p>
      </td>
      <td width="358">
      <p>Prepare to check-out</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>11:00 - 12:00</p>
      </td>
      <td width="358">
      <p>Light lunch</p>
      </td>
      <td width="253">
      <p>Refer to Day 3 Menu</p>
      </td>
      <td width="158">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr>
      <td width="104">
      <p>12.00</p>
      </td>
      <td width="358">
      <p>Farewell + transfer to airport</p>
      </td>
      <td width="253">
      <p>&nbsp;</p>
      </td>
      <td width="158">
      <p>Komodo Airport</p>
      </td>
      </tr>
      </tbody>
    </table>
  
    
    `,
    addtionalInfo: `
    <ul>
    <li>Maximum occupancy 20 guests. No diving offered. (24-hours no flight rule, make this a no-possibility)</li>
    <li>To offer the best possible experience; Contract/deposit + all additional request (ADD ON) must be finalised one week prior to trip departure.</li>
    <li>Due to the remoteness of the areas we operate, The Trans Luxury Yacht can&rsquo;t guarantee fulfillment of any specific request solicited 7 days before trip departure.</li>
    <li>Contract agreement should be signed 14 days prior to departure.</li>
  </ul>
    `,
  },
  {
    id: 2,
    title: "4 DAYS 3 NIGHTS",
    subTitle: "ITTENARY - 4 DAYS 3 NIGHTS FULL BOARD (EXCLUDING ALCOHOL)",
    image: "/images/voyage5.jpeg",
    slug: "4-days-5-night",
    table: `
    <table width="1040">
      <thead>
      <tr>
      <td width="142">
      <p><strong>DATE</strong></p>
      </td>
      <td width="151">
      <p><strong>TIME</strong></p>
      </td>
      <td width="378">
      <p><strong>ACTIVITY</strong></p>
      </td>
      <td width="198">
      <p><strong>ATTRACTION</strong></p>
      </td>
      <td width="170">
      <p><strong>VENUE</strong></p>
      </td>
      </tr>
      </thead>
      <tbody>
      <tr>
      <td rowspan="12" width="142" class="date">
      <p><strong>DAY 1</strong></p>
      </td>
      <td width="151">
      <p>9:00 / 9:30</p>
      </td>
      <td width="378">
      <p>Meet and Greet AT AIRPORT Luggage handling</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Komodo Airport</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>9:30</p>
      </td>
      <td width="378">
      <p>Heading to Komodo Jetty</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Labuan Bajo</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>9:30 - 10:00</p>
      </td>
      <td width="378">
      <p>Transfer to Yacht by x2 boats</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Labuan Bajo</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>10:00</p>
      </td>
      <td width="378">
      <p>Greet the guests at Yacht - Welcoming by the Cruise Director &amp; Crews</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Living area</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>10:30 - 11:00</p>
      </td>
      <td width="378">
      <p>Safety briefing / Escort to Cabins for freshen up</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Living area</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>11:00 - 14:30</p>
      </td>
      <td width="378">
      <p>Start cruising to Sabayor kecil as snack is served</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Sabayor Kecil</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>13:00</p>
      </td>
      <td width="378">
      <p>Lunch</p>
      </td>
      <td width="198">
      <p>Refer to Day 1 Menu</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>14.30</p>
      </td>
      <td width="378">
      <p>Arrival to Sabayor Kecil</p>
      </td>
      <td width="198">
      <p>Island with beautiful hard coral reefs</p>
      <p>and sandy spot. White beaches at Sabayor are perfect for relaxing.</p>
      </td>
      <td width="170">
      <p>Sabayor Kecil</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>15:00 - 18:30</p>
      </td>
      <td width="378">
      <p>Water activities: Check Dive / kayaking / snorkeling</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Sabayor Kecil</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>18:30 - 19:00</p>
      </td>
      <td width="378">
      <p>Relax and freshen up for dinner</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Rooftop &amp; cabins</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>19.00</p>
      </td>
      <td width="378">
      <p>Start cruising to Gili Lawa Laut &amp; Dinner</p>
      </td>
      <td width="198">
      <p>Refer to Day 1 Menu</p>
      </td>
      <td width="170">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>19:00 - 21:00</p>
      </td>
      <td width="378">
      <p>After dinner &amp; relax</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr class="yellow">
      <td rowspan="10" width="142" class="date">
      <p><strong>DAY 2</strong></p>
      </td>
      <td width="151">
      <p>7.00</p>
      </td>
      <td width="378">
      <p>Wake up at Gili Lawa Laut</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Gili Lawa Laut</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>07:00 - 09:00</p>
      </td>
      <td width="378">
      <p>Breakfast</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>09:00 - 11:30</p>
      </td>
      <td width="378">
      <p>&nbsp;</p>
      <p>Water Activities:</p>
      <p>Snorkel, Swimming / Kayaking at Gili Lawa Beach</p>
      <p><strong>(Diving optional)</strong></p>
      </td>
      <td width="198">
      <p>The northern island of Komodo. Where reefs and pinnacle of the Komodo National Park are irresistible magnet for fish and</p>
      <p>divers.</p>
      </td>
      <td width="170">
      <p>Gili Lawa Laut</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>12:30 - 13:30</p>
      </td>
      <td width="378">
      <p>Lunch</p>
      </td>
      <td width="198">
      <p>Refer to Day 2 Menu</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>13:30 - 15:00</p>
      </td>
      <td width="378">
      <p>Rest</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Rooftop &amp; cabins</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>&nbsp;</p>
      <p>15:00 - 18:00</p>
      </td>
      <td width="378">
      <p>Water Activities:</p>
      <p>Snorkel, Swimming / Kayaking at Gili Lawa Beach</p>
      <p><strong>(Diving optional)</strong></p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>&nbsp;</p>
      <p>Gili Lawa Laut</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>18:30 - 19:00</p>
      </td>
      <td width="378">
      <p>Sunset drinks / snack</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Rooftop</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>19:30 - 21:00</p>
      </td>
      <td width="378">
      <p>Dinner</p>
      </td>
      <td width="198">
      <p>Refer to Day 2 Menu</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>21.00</p>
      </td>
      <td width="378">
      <p>After dinner &amp; relax</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>22:00</p>
      </td>
      <td width="378">
      <p>Overnight cruise to Komodo</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr class="yellow">
      <td rowspan="13" width="142" class="date">
      <p><strong>DAY 3</strong></p>
      </td>
      <td width="151">
      <p>7.00</p>
      </td>
      <td width="378">
    
      <p>Wake up at PINK Beach</p>
      </td>
      <td width="198">
      <p>The inhabited beach with pink colored sand and an alluring view. A good spot for snorkeling, sunbathing</p>
      <p>and kayaking</p>
      </td>
      <td width="170">
      <p>Pink Beach</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>07:00 - 09:00</p>
      </td>
      <td width="378">
      <p>Breakfast</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>09:00 - 12:30</p>
      </td>
      <td width="378">
      <p>Water Activities:</p>
      <p>Snorkel, Swimming / Kayaking at PINK Beach</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Pink Beach</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>12:30 - 13:30</p>
      </td>
      <td width="378">
      <p>Cruise to Komodo Ranger Station</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Komodo Island</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>12:30 - 13:30</p>
      </td>
      <td width="378">
      <p>Lunch</p>
      </td>
      <td width="198">
      <p>Refer to Day 3 Menu</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>13:30 - 14:00</p>
      </td>
      <td width="378">
      <p>Speedboat to go trekking at Komodo Ranger Station</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Komodo Ranger Station</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>14:00 - 15:30</p>
      </td>
      <td width="378">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>Trekking</p>
      </td>
      <td width="198">
      <p>Explore the island and witness the life of Komodo dragons directly.</p>
      <p>Recommended to wear boots / training shoes as you may go</p>
      <p>through the savanna.</p>
      </td>
      <td width="170">
      <p>Komodo Ranger Station</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>15:30 - 16:00</p>
      </td>
      <td width="378">
      <p>Finish trekking</p>
      <p>Speedboat back to the Yacht</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Komodo Island</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>16:00 - 18:30</p>
      </td>
      <td width="378">
      <p>Arrival at Yacht for relax</p>
      <p>Use glass bottom boat to see coral, swimming / snorkeling, water activities</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Komodo Island</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>18:30 - 19:00</p>
      </td>
      <td width="378">
      <p>Sunset drinks / snack</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Rooftop</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>19:30 - 21:00</p>
      </td>
      <td width="378">
      <p>Dinner</p>
      </td>
      <td width="198">
      <p>Refer to Day 3 Menu</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>21.00</p>
      </td>
      <td width="378">
      <p>After dinner &amp; relax</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Living area</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>00.00</p>
      </td>
      <td width="378">
      <p>Overnight cruise to Bidadari</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>&nbsp;</p>
      </td>
      </tr>
      <tr>
      <td rowspan="7" width="142" class="date">
      <p><strong>DAY 4</strong></p>
      </td>
      <td width="151">
      <p>05:00 - 06:00</p>
      </td>
      <td width="378">
      <p>Wake and be greeted by dolphins</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Bidadari Island</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>06:00 - 08:00</p>
      </td>
      <td width="378">
      <p>Breakfast</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Dining Room</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>6.00</p>
      </td>
      <td width="378">
      <p>Arrival at Bidadari Island</p>
      </td>
      <td width="198">
      <p>Bidadari (Angel) Island is a beautiful small island in the heart of Komodo National Park. Well-known for its clear water and a long stretch of</p>
      <p>white sandy beach.</p>
      </td>
      <td width="170">
      <p>Bidadari Island</p>
      </td>
      </tr>
      <tr class="yellow">
      <td width="151">
      <p>08:00 - 10:00</p>
      </td>
      <td width="378">
      <p>Water activities</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Bidadari Island</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>10:00 - 11:00</p>
      </td>
      <td width="378">
      <p>Prepare to check out</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Bidadari</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>11:00 - 12:00</p>
      </td>
      <td width="378">
      <p>Light lunch</p>
      </td>
      <td width="198">
      <p>Refer to Day 4 Menu</p>
      </td>
      <td width="170">
      <p>Labuan Bajo</p>
      </td>
      </tr>
      <tr>
      <td width="151">
      <p>12.00</p>
      </td>
      <td width="378">
      <p>Farewell + transfer to airport</p>
      </td>
      <td width="198">
      <p>&nbsp;</p>
      </td>
      <td width="170">
      <p>Komodo Airport</p>
      </td>
      </tr>
      </tbody>
</table>
    `,
    addtionalInfo: `
    <ul>
    <li>Maximum occupancy 20 guests. No diving offered. (24-hours no flight rule, make this a no-possibility)</li>
    <li>In order to be offer the best possible experience;  Contract/deposit + all additional request (ADD ON) must be finalised one week prior to trip departure.</li>
    <li>Due to the remoteness of the areas we operate, The Trans Luxury Yacht can&rsquo;t guarantee fulfillment of any specific request solicited 7 days before trip departure.</li>
    <li>Contract agreement should be signed 14 days prior to departure.</li>
  </ul>
  <p style="margin-bottom:1rem; margin-top:1rem">Diving subject to:</p>
  <ul>
    <li>Each diver should bring the Dive certificate and Medical certificate.</li>
    <li>The final election of dive sites is subjected to Dive Master final decision (bad weather, strong currents, low viz, diver's skills...).</li>
  </ul>
    `,
  },
  {
    id: 3,
    title: "5 DAYS 4 NIGHTS",
    subTitle: "ITTENARY - 5 DAYS 4 NIGHTS  FULL BOARD (EXCLUDING ALCOHOL)",
    image: "/images/voyage7.jpeg",
    slug: "5-days-6-night",
    table: `
    <table width="995">
    <thead>
    <tr>
    <td width="115">
    <p><strong>DATE</strong></p>
    </td>
    <td width="159">
    <p><strong>TIME</strong></p>
    </td>
    <td width="328">
    <p><strong>ACTIVITY</strong></p>
    </td>
    <td width="223">
    <p><strong>ATTRACTION</strong></p>
    </td>
    <td width="170">
    <p><strong>VENUE</strong></p>
    </td>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td rowspan="14" width="115" class="date">
    <p><strong>DAY 1</strong></p>
    </td>
    <td width="159">
    <p>9:00 / 9:30</p>
    </td>
    <td width="328">
    <p>Meet and Greet AT AIRPORT Luggage handling</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Komodo Airport</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>9:30</p>
    </td>
    <td width="328">
    <p>Heading to Komodo Jetty</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Labuan Bajo</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>9:30 - 10:00</p>
    </td>
    <td width="328">
    <p>Transfer to Yacht by x2 boats</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Labuan Bajo</p>
    </td>
    </tr>
    <tr>
    <td rowspan="2" width="159">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>10:00</p>
    </td>
    <td width="328">
    <p>Greet the guests at Yacht - Welcoming by the Cruise Director &amp;</p>
    <p>Crews</p>
    </td>
    <td rowspan="2" width="223">
    <p>&nbsp;</p>
    </td>
    <td rowspan="2" width="170">
    <p>&nbsp;</p>
    <p>Living area</p>
    </td>
    </tr>
    <tr>
    <td width="328">
    <p>Seated at the Living Area</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>10:30 - 11:00</p>
    </td>
    <td width="328">
    <p>Safety briefing / Escort to Cabins for</p>
    <p>freshen up</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Living area</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>11:00 - 14:30</p>
    </td>
    <td width="328">
    <p>Start cruising to Sabayor kecil</p>
    <p>as snack is served</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Sabayor Kecil</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>13:00</p>
    </td>
    <td width="328">
    <p>Lunch</p>
    </td>
    <td width="223">
    <p>Refer to Day 1 Menu</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>14.30</p>
    </td>
    <td width="328">
    <p>Arrival to Sabayor Kecil</p>
    </td>
    <td width="223">
    <p>Island with beautiful hard coral reefs and sandy spot. White beaches at Sabayor are</p>
    <p>perfect for relaxing.</p>
    </td>
    <td width="170">
    <p>Sabayor Kecil</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>15:00 - 18:30</p>
    </td>
    <td width="328">
    <p>Water activities: Snorkel, swimming kayaking <strong>(Diving optional)</strong></p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Sabayor Kecil</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>18:30 - 19:00</p>
    </td>
    <td width="328">
    <p>Relax and freshen for dinner</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Rooftop &amp; cabins</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>19.00</p>
    </td>
    <td width="328">
    <p>Start cruising to Gili Lawa Laut</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>&nbsp;</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>19:00 - 21:00</p>
    </td>
    <td width="328">
    <p>Dinner</p>
    </td>
    <td width="223">
    <p>Refer to Day 1 Menu</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>21:00 - 22:00</p>
    </td>
    <td width="328">
    <p>After dinner &amp; relax</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>&nbsp;</p>
    </td>
    </tr>
    <tr class="yellow">
    <td rowspan="10" width="115" class="date">
    <p><strong>DAY 2</strong></p>
    </td>
    <td width="159">
    <p>7.00</p>
    </td>
    <td width="328">
    <p>Wake up at Gili Lawa Laut</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Gili Lawa Laut</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>07:00 - 09:00</p>
    </td>
    <td width="328">
    <p>Breakfast</p>
    </td>
    <td width="223">
    <p>Refer to Day 2 Menu</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>09:00 - 11:30</p>
    </td>
    <td width="328">
    <p>Water Activities:</p>
    <p>Snorkel, Swimming / Kayaking at Gili Lawa Beach <strong>(Diving optional)</strong></p>
    </td>
    <td width="223">
    <p>The northern island of Komodo. Where reefs and pinnacle of the Komodo National Park are irresistible magnet</p>
    <p>for fish and divers.</p>
    </td>
    <td width="170">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>Gili Lawa Laut</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>12:30 - 13:30</p>
    </td>
    <td width="328">
    <p>Lunch</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>13:30 - 15:00</p>
    </td>
    <td width="328">
    <p>Rest</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Rooftop &amp; cabins</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>15:00 - 18:00</p>
    </td>
    <td width="328">
    <p>Water Activities:</p>
    <p>Snorkel, Swimming / Kayaking at Gili Lawa Beach <strong>(Diving optional)</strong></p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>&nbsp;</p>
    <p>Gili Lawa Laut</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>18:30 - 19:00</p>
    </td>
    <td width="328">
    <p>Sunset drinks/snack</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Rooftop</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>19:30 - 21:00</p>
    </td>
    <td width="328">
    <p>Dinner</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>21.00</p>
    </td>
    <td width="328">
    <p>After dinner &amp; relax</p>
    </td>
    <td width="223">
    <p>Refer to Day 2 Menu</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>22:00</p>
    </td>
    <td width="328">
    <p>Overnight cruise to Komodo</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>&nbsp;</p>
    </td>
    </tr>
    </tr>
    <tr class="yellow">
    <td width="115" rowspan="13" class="date">
    <p><strong>DAY 3</strong></p>
    </td>
    <td width="159">
    <p>7.00</p>
    </td>
    <td width="328">
    <p>Wake up at PINK Beach</p>
    </td>
    <td width="223">
    <p>The inhabited beach
    with pink colored sand
    and an alluring view. A
    good spot for
    snorkeling, sunbathing
    and kayaking
    </p>
    </td>
    <td width="170">
    <p>Pink Beach</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>07:00 - 09:00</p>
    </td>
    <td width="328">
    <p>Breakfast</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>09:00 - 12:00</p>
    </td>
    <td width="328">
    <p>Water Activities:
    Snorkel, Swimming / Kayaking at
    PINK Beach (Diving optional)
    </p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Komodo Island</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>12:30 - 13:30</p>
    </td>
    <td width="328">
    <p>Cruise to Komodo Ranger Station</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Komodo Island</p>
    </td> 
    </tr>
    <tr>
    <td width="159">
    <p>12:30 - 13:30</p>
    </td>
    <td width="328">
    <p>Lunch</p>
    </td>
    <td width="223">
    <p>Refer to Day 3 Menu</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>13:30 - 14:00</p>
    </td>
    <td width="328">
    <p>Speedboat to go trekking at Komodo Ranger Station</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Komodo Ranger Station</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>&nbsp;</p>
    <p>14:00 - 15:30</p>
    </td>
    <td width="328">
    <p>&nbsp;</p>
    <p>Trekking</p>
    </td>
    <td width="223">
    <p>Explore the island and witness the life of
    Komodo dragons
    directly. Recommended to wear boots / training
    shoes as you may go
    through the savanna.
    </p>
    </td>
    <td width="170">
    <p>Komodo Ranger Station</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>15:30 - 16:00</p>
    </td>
    <td width="328">
    <p>Finish trekking. Speedboat back to the Yacht</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Komodo Island</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>16:00 - 18:30</p>
    </td>
    <td width="328">
    <p>Arrival at Yacht for relax
    Use glass bottom boat to see coral,
    swimming / snorkeling, water
    activities
    </p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Komodo Island</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>18:30 - 19:00</p>
    </td>
    <td width="328">
    <p>Sunset drinks / snack</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Rooftop</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>19:30 - 21:00</p>
    </td>
    <td width="328">
    <p>Dinner</p>
    </td>
    <td width="223">
    <p>Refer to Day 3 Menu</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>21.00</p>
    </td>
    <td width="328">
    <p>After dinner &amp; relax</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Living area</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>00:00</p>
    </td>
    <td width="328">
    <p>Overnight cruise to Wainilu Island</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>&nbsp;</p>
    </td>
    </tr>
    <tr>
    <td width="115" rowspan="9" class="date">
    <p><strong>DAY 4</strong></p>
    </td>
    <td width="159">
    <p>07:00 - 09:00</p>
    </td>
    <td width="328">
    <p>Breakfast</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>09:00 - 11:30</p>
    </td>
    <td width="328">
    <p>Water Activities:</p>
    <p>Snorkel, Swimming / Kayaking at Wainilu Beach</p>
    </td>
    <td width="223">
    <p>Not far from Rinca island, on the north side, Wainilu is a little paradise for macro lovers. Perfect for photographers and</p>
    <p>divers of all levels.</p>
    </td>
    <td width="170">
    <p>Wainilu Island</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>12:00 - 13:30</p>
    </td>
    <td width="328">
    <p>Lunch</p>
    </td>
    <td width="223">
    <p>Refer to Day 4 Menu</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>14:00 - 15:30</p>
    </td>
    <td width="328">
    <p>Rest &amp; relax</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Living Room</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>&nbsp;</p>
    <p>16:00 - 18:30</p>
    </td>
    <td width="328">
    <p>Water Activities:</p>
    <p>Snorkel, Swimming / Kayaking at Wainilu Beach</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>&nbsp;</p>
    <p>Wainilu Island</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>18:30 - 19:00</p>
    </td>
    <td width="328">
    <p>Visit to Pulau Koaba by Speedboat. See ﬂying Foxes -</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Koaba Island</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
    <p>19:30 - 20:30</p>
    </td>
    <td width="328">
    <p>Sunset drinks &amp; snack</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Rooftop</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>20.30 &ndash; 22:00</p>
    </td>
    <td width="328">
    <p>Dinner &amp; relax</p>
    </td>
    <td width="223">
    <p>Refer to Day 4 Menu</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>00.00</p>
    </td>
    <td width="328">
    <p>Overnight cruise to Bidadari</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>&nbsp;</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="115" rowspan="7" class="date">
    <p>DAY 5</p>
    </td>
    <td width="159">
    <p>05:00 - 06:00</p>
    </td>
    <td width="328">
    <p>Wake and be greeted by dolphins</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Bidadari Island</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>06:00 - 08:00</p>
    </td>
    <td width="328">
    <p>Breakfast</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Dining Room</p>
    </td>
    </tr>
    <tr class="yellow">
    <td width="159">
   
    <p>6.00</p>
    </td>
    <td width="328">
    
    <p>Arrival at Bidadari Island</p>
    </td>
    <td width="223">
    <p>Bidadari (Angel) Island is a beautiful small island in the heart of Komodo National Park. Well- known for its clear water and a long stretch of white sandy beach.</p>
    </td>
    <td width="170">
 
    <p>Bidadari Island</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>08:00 - 10:00</p>
    </td>
    <td width="328">
    <p>Water activities</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Bidadari Island</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>10:00 - 11:00</p>
    </td>
    <td width="328">
    <p>Prepare to check out</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Bidadari Island</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>11:00 - 12:00</p>
    </td>
    <td width="328">
    <p>Light lunch</p>
    </td>
    <td width="223">
    <p>Refer to Day 5 Menu</p>
    </td>
    <td width="170">
    <p>Labuan Bajo</p>
    </td>
    </tr>
    <tr>
    <td width="159">
    <p>12.00</p>
    </td>
    <td width="328">
    <p>Farewell + transfer to airport</p>
    </td>
    <td width="223">
    <p>&nbsp;</p>
    </td>
    <td width="170">
    <p>Komodo Airport</p>
    </td>
    </tr>
    </tbody>
    </table>
    `,
    addtionalInfo: `
    <ul>
      <li>Maximum occupancy 20 guests. No diving offered. (24-hours no flight rule, make this a no-possibility)</li>
      <li>In order to be offer the best possible experience; Contract/deposit + all additional request (ADD ON), must be finalised one week prior to trip departure.</li>
      <li>Due to the remoteness of the areas we operate, The Trans Luxury Yacht can&rsquo;t guarantee fulfillment of any specific request solicited 7 days before trip departure.</li>
      <li>Contract agreement should be signed 14 days prior to departure </li>
      
      <p style="margin-bottom:1rem; margin-top:1rem;">Diving subject to:</p>
      <li>Each diver should bring the Dive certificate and Medical certificate.</li>
      <li>The final election of dive sites is subjected to Dive Master final decision.
      <br/>(bad weather, strong currents, low viz, diver's skills...)</li>
      </ul>
    `,
  },
];

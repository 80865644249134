import { useState } from "react";
import { useWindow } from "../../helpers/useWindow";
import "./popupgallery.css";

function PopUpGallery({ setOpen, open, dataGallery }) {
  const [indexReg, setIndexReg] = useState(0);

  // SET MOBILE BACKGROUND
  let layer = useWindow();

  let rangeSlide;

  if (layer >= 768) {
    rangeSlide = -80;
  } else {
    rangeSlide = -90;
  }

  const handleArrow = (direction) => {
    if (direction === "l") {
      setIndexReg(indexReg !== 0 ? indexReg - 1 : dataGallery.length - 1);
    }
    if (direction === "r") {
      setIndexReg(indexReg !== dataGallery.length - 1 ? indexReg + 1 : 0);
    }
  };

  return (
    <div className="popupgallery">
      <div className="close-pop" onClick={() => setOpen([])}>
        &#10007;
      </div>
      <div className="popupgallery-container">
        {dataGallery.length > 1 && (
          <div
            className="arrow"
            style={{ left: `${layer >= 768 ? "1.5rem" : "0.2rem"}` }}
            onClick={() => handleArrow("l")}
          >
            &lt;
          </div>
        )}
        <div
          className="cards-slider"
          style={{ transform: `translateX(${rangeSlide * indexReg}vw)` }}
        >
          {dataGallery?.map((img, idx) => (
            <div className="card-wrapper" key={idx}>
              <div className="image-container">
                <img src={img.url} alt="" />
              </div>
            </div>
          ))}
        </div>
        {dataGallery?.length > 1 && (
          <div
            className="arrow"
            style={{ right: `${layer >= 768 ? "1.5rem" : "0.2rem"}` }}
            onClick={() => handleArrow("r")}
          >
            &gt;
          </div>
        )}
      </div>
    </div>
  );
}

export default PopUpGallery;

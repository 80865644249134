export const dataDining = {
  title: "Dining Room",
  type: [
    {
      id: 1,
      name: "Indoor",
      featuredImg: "/images/room5.png",
      gallery: [
        { title: "1", url: "/images/DINING ROOM/821A0805.jpg" },
        { title: "2", url: "/images/DINING ROOM/821A0886.jpg" },
        { title: "3", url: "/images/DINING ROOM/DSCF7843.jpg" },
      ],
    },
    {
      id: 2,
      name: "Outdoor",
      featuredImg: "/images/room6.png",
      gallery: [{ title: "1", url: "/images/DINING OUTDOOR/DSCF7860.jpg" }],
    },
    {
      id: 3,
      name: "Bar & Sundeck Bar",
      featuredImg: "/images/room4.png",
      gallery: [],
    },
  ],
};

import "./exclusivepage.css";
import { exclusive } from "../../data/exclusive";
import GoToTop from "../../helpers/goToTop";
import { useState } from "react";
import Packages from "../../components/packages/Packages";
import { useWindow } from "../../helpers/useWindow";

function ExclusivePage() {
  // SET MOBILE BACKGROUND
  let layer = useWindow();

  let imageHeader;

  if (layer >= 768) {
    imageHeader = "/images/header-exclusive.jpg";
  } else {
    imageHeader = "/images/BANNER MOBILE/header-exclusive-mobile.jpg";
  }

  const [packages, setPackages] = useState(null);

  return (
    <div className="exclusive-page">
      <GoToTop />
      <img src={imageHeader} alt="Yacht" />
      <div className="contents-container">
        <div className="contents-title">
          <h3>Exclusive Offer</h3>
          <p>
            Inviting you to take a memorable voyage with friends and family with
            an unrivalled experience on board
          </p>
        </div>
        {/* Card Offer */}
        <div className="itinerary-wrapper-card">
          {exclusive &&
            exclusive.map((item) => (
              <div className="card-itinerary" key={item.id}>
                <img src={item.image} alt="" />
                <div className="contents-itinerary">
                  <div className="content-firstline">
                    <div className="from-to">
                      <div className="days">
                        <span>{item.offer}</span>
                        <span>exclude alcoholic drinks</span>
                      </div>
                      {/* <div className="from">{item.from}</div>
                      <p>- To -</p>
                      <div className="to">{item.to}</div> */}
                    </div>
                    <div className="love">
                      <img src="/images/love.svg" alt="love" />
                    </div>
                  </div>
                  {/* <div className="content-secondline">
                    <div className="departs">
                      <div className="depart">depart</div>
                      <div className="tanggal-departs">
                        {item.tanggal_departs}
                      </div>
                      <div className="tahun-departs">{item.tahun_departs}</div>
                    </div>
                    <div className="duration">
                      <div className="depart">duration</div>
                      <div className="tanggal-departs">{item.malam}</div>
                      <div className="tahun-departs">night</div>
                    </div>
                    <div className="fares-from">
                      <div className="depart">fares</div>
                      <div className="tanggal-departs">
                        <span className="idr">idr</span> {item.fares_from}{" "}
                        <span className="mio">mio</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="content-thirdline">
                    <div className="embarking">Routes</div>
                    <div className="embarking-content">{item.routes}</div>
                  </div>

                  <div className="content-thirdline">
                    <div className="embarking">Fares</div>
                    <div className="embarking-content">{item.fareWeekday}</div>
                    <div className="embarking-content">{item.fareWeekend}</div>
                  </div>

                  {/* <div className="content-thirdline">
                    <div className="embarking">Embarking / Disembarking</div>
                    <div className="embarking-content">{item.embarking}</div>
                  </div> */}
                  {/* <div className="content-fourthline"> */}
                  {/* <div className="request-quote">Request A Quote &gt;</div> */}
                  {/* <div
                      className="button-view"
                      onClick={() => setPackages(item)}
                    >
                      View Routes
                    </div> */}
                  {/* </div> */}
                  {/* <div className="content-fiveline"> */}
                  {/* <div className="request-quote">Request A Quote &gt;</div>
                  <div className="compare">Compare</div> */}
                  {/* <form className="content-fiveline">
                    <input
                      type="checkbox"
                      name="compare"
                      id={item.id}
                      value={item.id}
                    />
                    <label htmlFor={item.id}>compare</label>
                  </form> */}
                  {/* </div> */}
                </div>
                {/* Packages */}
              </div>
            ))}
        </div>
      </div>
      {packages && <Packages setPackages={setPackages} packages={packages} />}
    </div>
  );
}

export default ExclusivePage;

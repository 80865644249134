export const exclusive = [
  {
    id: 1,
    hari: 1,
    malam: 4,
    from: "bali",
    to: "komodo",
    tanggal_departs: "dec 11",
    offer: "1 day trip full board",
    tahun_departs: "2021",
    fares_from: "100",
    fareWeekday: "International Price: USD 15,000 ++ full day trip",
    fareWeekend: "Domestic Price: IDR 150,000,000 ++ full day trip",
    routes:
      "labuan bajo - komodo island - pink beach - koaba island - labuan bajo",
    embarking: "labuan bajo / serangan bali",
    image: "/images/additional-exclusive2.jpg",
  },
  {
    id: 2,
    hari: 2,
    malam: 1,
    from: "bali",
    to: "komodo",
    tanggal_departs: "dec 11",
    offer: "2 days 1 night full board",
    tahun_departs: "2023",
    fares_from: "100",
    fareWeekday: "International Price: USD 15,000 ++/day",
    fareWeekend: "Domestic Price: IDR 150,000,000 ++/day",
    routes:
      "LABUAN BAJO - KOABA ISLAND - PADAR ISLAND - KOMODO ISLAND - PINK BEACH - LABUAN BAJO",
    embarking: "labuan bajo / serangan bali",
    image: "/images/additional-exclusive1.jpg",
  },
  {
    id: 3,
    hari: 3,
    malam: 2,
    from: "komodo",
    to: "",
    tanggal_departs: "dec 11",
    offer: "3 days 2 nights full board",
    tahun_departs: "2021",
    fares_from: "100",
    fareWeekday: "Internatioanl Price USD 15,000 ++/day",
    fareWeekend: "Domestic Price: IDR 150,000,000 ++/day",
    routes:
      "LABUAN BAJO - KOABA ISLAND - PADAR ISLAND - KOMODO ISLAND - PINK BEACH - BIDADARI ISLAND - LABUAN BAJO",
    embarking: "labuan bajo",
    image: "/images/exclusive1.jpg",
    details: [
      { day: 1, destinasi: "LABUAN BAJO - KANAWA - SEBAYUR KECIL" },
      {
        day: 2,
        destinasi: "PADAR - KOMODO ISLAND - PINK BEACH - KARANG MAKASAR",
      },
      { day: 3, destinasi: "KELOR - LABUAN BAJO" },
    ],
  },
  {
    id: 4,
    hari: 4,
    malam: 3,
    from: "komodo",
    to: "",
    tanggal_departs: "dec 11",
    offer: "4 days 3 nights full board",
    tahun_departs: "2021",
    fares_from: "100",
    fareWeekday: "International Price: USD 15,000 ++/day",
    fareWeekend: "Domestic Price: IDR 150,000,000 ++/day",
    routes:
      "Labuan Bajo - Sabayor Kecil - Gili Lawa Laut - Pink Beach - Komodo Island - Bidadari Island - Labuan Bajo",
    embarking: "labuan bajo",
    image: "/images/exclusive2.jpg",
    details: [
      { day: 1, destinasi: "LABUAN BAJO - KANAWA - SEBAYUR KECIL" },
      {
        day: 2,
        destinasi: "KOMODO ISLAND - PINK BEACH - KARANG MAKASAR",
      },
      { day: 3, destinasi: "PADAR - NUSA KODE - SIABA BESAR" },
      { day: 4, destinasi: "KELOR ISLAND - LABUAN BAJO" },
    ],
  },
  {
    id: 5,
    hari: 5,
    malam: 4,
    from: "bali",
    to: "komodo",
    tanggal_departs: "dec 11",
    offer: "5 days 4 nights full board",
    tahun_departs: "2021",
    fares_from: "100",
    fareWeekday: "International Price: USD 15,000 ++/day",
    fareWeekend: "Domestic Price: IDR 150,000,000 ++/day",
    routes:
      "Labuan Bajo - Sabayor Kecil - Gili Lawa Laut - Pink Beach - Komodo Island - Wainilu Island - Koaba Island - Bidadari Island - Labuan Bajo",
    embarking: "labuan bajo / serangan bali",
    image: "/images/exclusive3.jpg",
    details: [
      { day: 1, destinasi: "BALI -  GILI ISLANDS" },
      {
        day: 2,
        destinasi: "TELUK SALEH - MOYO - SATONDA",
      },
      { day: 3, destinasi: "SANGEANG - GILI BANTA" },
      { day: 4, destinasi: "KARANG MAKASSAR - PADAR ISLAND - PINK BEACH" },
      { day: 5, destinasi: "KOMODO ISLAND - SIABA - LE PIRATE ISLAND" },
      { day: 6, destinasi: "KELOR ISLAND - LABUAN BAJO" },
    ],
  },
];

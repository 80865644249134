import "./hero.css";

function Hero() {
  return (
    <div className="hero">
      <img
        className="background-ornamen"
        src="/images/ornamen-home.png"
        alt=""
      />
      <div className="hero-container">
        <img src="/images/cover-baru-lagi.jpg" alt="hero" />
        <div className="contents">
          <h3>The Trans Luxury Yacht </h3>
          <p>
            Rebuild from the M.V Salila, this 58-meter luxury yacht is a truly
            magnificent charter vessel with distinctive interior design and
            alluring features such as 2 suites and 8 spacious cabins comfortably
            accommodating 20 guests over 5 tiers of dining, entertainment,
            business, and relaxation options.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Hero;

import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./footer.css";

function Footer() {
  const [subcribe, setSubcribe] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        "https://tlycms.tmsosial.asia/api/subscribe",
        {
          email: subcribe,
        }
      );
      toast.success(`${subcribe} ${res.data.msg}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Mengosongkan input
      setSubcribe("");
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="footer-image">
          <Link to={"/"}>
            <img src="/images/logo-bawah.png" alt="logo" />
          </Link>
        </div>
        <div className="footer-kontak">
          <div className="kontak-wrapper">
            <img src="/images/call.svg" alt="" />
            <div className="content-kontak">+62 (361) 898 1234</div>
          </div>
          <div className="kontak-wrapper">
            <a
              href="https://api.whatsapp.com/send?phone=628118200247"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/wa.svg" alt="" />
            </a>
            <div className="content-kontak">+62 811 8200 247</div>
          </div>
          <div className="kontak-wrapper">
            <a href="mailto:inquiry@transluxuryyacht.com">
              <img src="/images/email.svg" alt="" />
            </a>
            <div className="content-kontak">inquiry@transluxuryyacht.com</div>
          </div>
        </div>
        <div className="footer-nav">
          <ul>
            <li>About</li>
            {/* <li>Press &amp; Blog</li> */}
            <li>Privacy Policy</li>
          </ul>
        </div>
        <div className="footer-subcribe">
          <div className="subcribe-title">Subscribe to newsletter</div>
          <form className="subcribe-form" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Your email"
              value={subcribe}
              onChange={(e) => setSubcribe(e.target.value)}
            />
            <button type="submit">Subsrcibe</button>
          </form>
          <div className="subcribe-images">
            <a
              href="https://www.instagram.com/thetransluxuryyacht/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/ig.svg" alt="" />
            </a>
            <a
              href="https://www.facebook.com/thetransluxuryyacht.official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/fb.svg" alt="" />
            </a>
            {/* <a
              href="https://www.instagram.com/thetransluxuryyacht/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/yt.svg" alt="" />
            </a> */}
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Footer;

import GoToTop from "../../helpers/goToTop";
import "./voyagepage.css";
import Voyage from "../../components/voyage/Voyage";
import { useWindow } from "../../helpers/useWindow";

function VoyagePage() {
  // SET MOBILE BACKGROUND
  let layer = useWindow();

  let imageHeader;

  if (layer >= 768) {
    imageHeader = "/images/header-voyage.jpg";
  } else {
    imageHeader = "/images/BANNER MOBILE/header-voyage-mobile.jpg";
  }
  return (
    <div className="voyagepage">
      <GoToTop />
      {/* <img src="/images/yacht-page.jpg" alt="Yacht" /> */}
      <img src={imageHeader} alt="Yacht" />
      <Voyage />
    </div>
  );
}

export default VoyagePage;

import "./facilitiespage.css";
import GoToTop from "../../helpers/goToTop";
import { useWindow } from "../../helpers/useWindow";

function FacilitiesPage() {
  // SET MOBILE BACKGROUND
  let layer = useWindow();

  let imageHeader;

  if (layer >= 768) {
    imageHeader = "/images/header-facilities.jpg";
  } else {
    imageHeader = "/images/BANNER MOBILE/header-facilities-mobile.jpg";
  }

  return (
    <div className="facilities-page">
      <GoToTop />
      {/* <img src="/images/yacht-page.jpg" alt="Yacht" /> */}
      <img src={imageHeader} alt="Yacht" />
      <div className="contents-container">
        <div className="contents-title">
          <h3>Facilities</h3>
          <h4>Living Room</h4>
          <p>
            A cozy &amp; sophisticated Living Room, Spacious room equipped with
            Smart LED TV and Audio equipment
          </p>
        </div>
        {/* <div className="flex-images">
          <div className="img-item">
            <img src="/images/Dining-Indoor-2.jpg" alt="" />
          </div>
          <div className="img-item">
            <img src="/images/Single-bath.jpg" alt="" />
          </div>
          <div className="img-item">
            <img src="/images/Living-room.jpg" alt="" />
          </div>
        </div> */}
        <div className="grid-images">
          <div className="item1">
            <img src="/images/Living-Room-3.jpg" alt="" />
          </div>
          <div className="item2">
            <img src="/images/Living-room-Outdoor.jpg" alt="" />
          </div>
          <div className="item3">
            <img src="/images/BAR-Indoor.jpg" alt="" />
          </div>
        </div>
        {/* Rooms */}
        {/* <div className="contents-title">
          <h3>Rooms</h3>
          <p>
            Two spacious Suites at 29m2, all with ensuite bathroom &amp;
            bathtub, sofa bed and working desk.
          </p>
          <p>
            Comfortable Cabins 19m2 (twin bed) &amp; 24m2 (double bed) all with
            ensuite bathroom and working desk.
          </p>
          <p>
            Bathroom design meticulously fitted with exclusive Acqua di Parma
            amenities and exclusive finishing using the Serpiente marble from
            Italy.
          </p>
        </div>

        <div className="grid-images">
          <div className="item1">
            <img src="/images/Suites-Bed.jpg" alt="" />
          </div>
          <div className="item2">
            <img src="/images/Suites-Bathroom.jpg" alt="" />
          </div>
          <div className="item3">
            <img src="/images/Suites-Bathub.jpg" alt="" />
          </div>
        </div> */}
        {/* Twin */}
        {/* <p className="p-title">Twin</p>
        <div className="flex-images">
          <div className="img-item-v2">
            <img src="/images/Twin-bed.jpg" alt="" />
          </div>
          <div className="img-item-v2">
            <img src="/images/Twin-bath.jpg" alt="" />
          </div>
        </div> */}
        {/* Single */}
        {/* <p className="p-title">Single</p>
        <div className="flex-images">
          <div className="img-item-v2">
            <img src="/images/Single-bed.jpg" alt="" />
          </div>
          <div className="img-item-v2">
            <img src="/images/Single-bath.jpg" alt="" />
          </div>
        </div> */}
        {/* Meeting Room */}
        <div className="meeting-room-wrapper">
          <div className="content-meeting-room">
            <h4>Meeting Room</h4>
            <p>
              Meeting Area to 20 persons capacity A multi-purpose space for
              board meetings or special occasion featuring state-of-the-art
              audio and visual equipment and a clear view over the ocean.
            </p>
          </div>
          <div className="images-meeting-room">
            <img src="/images/meeting-room1.png" alt="" />
            <img src="/images/meeting-room-2.jpg" alt="" />
          </div>
        </div>
        {/* Dining Room */}
        <div className="meeting-room-wrapper">
          <div className="images-meeting-room">
            <img src="/images/diningroom1.png" alt="" />
            <img src="/images/diningroom2.png" alt="" />
          </div>
          <div className="content-meeting-room left">
            <h4>Dining Room</h4>
            <p>
              The Trans Luxury Yacht offers 5-star dining experience beyond the
              sea. Inspired by Asian traditions with modern twists, Chefs on
              board provide varieties of full board menu from healthy breakfast,
              family style lunch, barbeque by the beach as well as serving set
              menu for an intimate dining experience.
            </p>
          </div>
        </div>
        {/* Rooms */}
        <div className="meeting-room-wrapper">
          <div className="content-meeting-room left">
            <h4>Rooms</h4>
            <p>
              Comfortable Cabins Twin bed &amp; Double bed all with ensuite
              bathroom and working. Bathroom design meticolously fitted with
              with exclusive Acqua di Parma amenities and exclusive finishing
              using the Serpiente marble from Italy.
            </p>
          </div>
          <div className="images-meeting-room">
            <img src="/images/Single-bed.jpg" alt="" />
            <img src="/images/Single-bath.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FacilitiesPage;

import "./tagline.css";

function Tagline() {
  return (
    <div className="tagline">
      <div className="tagline-wrapper">
        <div className="tagline-first">A Journey Beyond The Sea</div>
        <div className="tagline-second">“Feel the luxury from the sea”</div>
      </div>
    </div>
  );
}

export default Tagline;

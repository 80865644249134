import axios from "axios";
import { useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { countries } from "../../data/countries";
import "./enquire.css";

function Enquire({ setEnquire }) {
  // SET CLOSE
  const contentRef = useRef();
  const handleClose = () => {
    contentRef.current.style.animation = "hidden 1s";
    setTimeout(() => {
      setEnquire(false);
    }, 1000);
  };

  // HANDLE SUBMIT

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [telephone, setTelephone] = useState("");
  const [paket, setPaket] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("https://tlycms.tmsosial.asia/api/enquire", {
        f_name: firstName,
        l_name: lastName,
        email: email,
        no_hp: telephone,
        country: country,
        booking_name: paket,
      });

      // console.log("res.data :>> ", res.data.msg);
      toast.success(res.data.msg, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        setEnquire(false);
      }, 2000);
    } catch (error) {
      // console.log("error.response :>> ", error.response.data.msg);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="enquire">
      <div className="container-enquire" ref={contentRef}>
        <div className="img-enquire">
          <img src="/images/SUN-Roof.jpg" alt="" />
        </div>
        <div className="content-enquire">
          <div className="close-enquire" onClick={handleClose}>
            close x
          </div>
          <div className="desc-content-enquire">
            <span></span>
            <h3>Trans Luxury Yacht</h3>
            <p>LET SAIL WITH US!</p>
            <form onSubmit={handleSubmit}>
              <div className="group-input">
                <label htmlFor="firstname">First/Given Name</label>
                <input
                  type="text"
                  id="firstname"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="group-input">
                <label htmlFor="lastname">Last/Family Name</label>
                <input
                  type="text"
                  id="lastname"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="group-input">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="group-input">
                <label htmlFor="country">Your Country of Residence</label>
                <select
                  name="country"
                  id="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries.map((country, idx) => (
                    <option value={country.name} key={idx}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="group-input">
                <label htmlFor="telephone">Telephone Number</label>
                <input
                  type="telephone"
                  id="telephone"
                  onChange={(e) => setTelephone(e.target.value)}
                />
              </div>
              <div className="group-input">
                <label htmlFor="packet">
                  I am currently booked on a Trans Luxury Yacht
                </label>
                <select
                  name="packet"
                  id="packet"
                  value={paket}
                  onChange={(e) => setPaket(e.target.value)}
                >
                  <option value="3 Days Night Komodo">
                    3 Days Night Komodo
                  </option>
                  <option value="4 Days 3 Night Komodo">
                    4 Days 3 Night Komodo
                  </option>
                  <option value="7 Days 6 Night Bali Komodo">
                    7 Days 6 Night Bali Komodo
                  </option>
                  <option value="8 Days 7 Night Komodo">
                    8 Days 7 Night Komodo
                  </option>
                </select>
              </div>
              <span className="span-enquire">privacy notice</span>
              <button className="submit-enquire" type="submit">
                submit request
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Enquire;

import { useState, useEffect } from "react";
import Enquire from "../enquire/Enquire";
import NavItem from "../NavItem/NavItem";
import NavMobile from "../navmobile/NavMobile";
import "./navbar.css";
import { ReactComponent as OpenLogo } from "../../images/open.svg";
import { ReactComponent as CloseLogo } from "../../images/close.svg";
import { NavLink } from "react-router-dom";

function Navbar() {
  const [isEnquire, setEnquire] = useState(false);

  // OPEN MENU NAVBAR
  const [open, setOpen] = useState(false);

  // SET NAVBAR DINAMIS
  const [solid, setSolid] = useState(false);
  const navbar = solid ? "navbar solid" : "navbar";

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 120;
      if (show) {
        setSolid(true);
      } else {
        setSolid(false);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isEnquire && <Enquire setEnquire={setEnquire} />}
      {/* Navbar */}
      <div className={`${navbar} ${open && "navdark"}`}>
        <div className="navbar-container">
          <div className="navbar-logo">
            <NavLink to="/">
              <img src="/images/logo2.png" alt="" className="logo" />{" "}
            </NavLink>
          </div>
          <div className="navigation">
            <NavItem setEnquire={setEnquire} setOpen={setOpen} />
            {/* <ul>
              <li>
                <NavLink to="/yacht">
                  <span>The Yacht</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/facilities">
                  <span>Facilities</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/voyage">
                  <span>Voyage</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  <img src="/images/logo2.png" alt="" className="logo" />{" "}
                </NavLink>
              </li>

              <li>
                <NavLink to="/gallery">
                  <span>Gallery</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/exclusive-offer">
                  <span> Exclusive Offer</span>{" "}
                </NavLink>
              </li>
              <li onClick={() => setEnquire(true)}>
                <span className="enquire-btn">Enquire</span>
              </li>
            </ul> */}
          </div>
        </div>
        {/* <div className="translate">EN</div> */}
        <div className="button-menu">
          {open ? (
            <button onClick={() => setOpen(!open)}>
              {/* <img src="/images/close.svg" alt="" /> */}
              <CloseLogo />
            </button>
          ) : (
            <button onClick={() => setOpen(!open)}>
              {/* <img src="/images/open.svg" alt="" /> */}
              <OpenLogo />
            </button>
          )}
        </div>
      </div>
      {open && <NavMobile setOpen={setOpen} setEnquire={setEnquire} />}
    </>
  );
}

export default Navbar;
